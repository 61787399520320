import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CouponsSubscribePage } from './coupons-subscribe.page';

const routes: Routes = [
  {
    path: '',
    component: CouponsSubscribePage
  },
  {
    path: 'tabs',
    loadChildren: () => import('../../tabs/tabs.module').then(m => m.TabsPageModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CouponsSubscribePageRoutingModule {}
