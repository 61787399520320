import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { TravelService } from 'src/app/services/travel.service';
import { UserOldService } from 'src/app/services/user_old.service';

@Component({
  selector: 'app-display',
  templateUrl: './display.page.html',
  styleUrls: ['./display.page.scss'],
})
export class DisplayPage implements OnInit {

  items;
  itemNumShow = false;
  itemNumbers;
  travel;

  constructor(
    public travelService: TravelService,
    private router: Router,
  ) {
}

  ngOnInit() {
    this.travel = this.travelService.getSelectedTravel();
  }

  brochureclick() {
    this.router.navigateByUrl('travel/travelbrochure');
  }

  tsandCsClick() {
    this.router.navigateByUrl('travel/travel-termsandconditions');
  }

}
