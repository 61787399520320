import { ApiService } from './api.service';
import { Injectable,  } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TravelService {

  travels = [
    {id: 0, image: 'assets/images/mango-airlines.jpg', logo: 'assets/images/mango.png',
      special: '5% discount on MANGO Airlines normal flights & 15% discount on MANGO PLUS flights' +
      ' (includes Bidvest lounge access, R70 on-board food & berverage voucher and FREE flight changes)', title: 'Flights'},
    { id: 1, image: 'assets/images/travel-bus.png', logo: 'assets/images/intercape-logo.png',
      special: '10% Discount on all advertised Intercape bus routes', title: 'Bus Tours'},
    {id: 2, image: 'assets/images/car-rentals-islamabad.png', logo: 'assets/images/bidvest-hertz.png',
      special: 'Bidvest Car Rental and Hertz with 250 KM\'s per day no excess on Hold', title: 'Car Hire'},
    {id: 3, image: 'assets/images/shosholoza.jpg', logo: 'assets/images/shosholoza-meyl.png',
      special: '10% Discount on Shosholoza train bookings and 15% off Premier Class tickets on journeys throughout South Africa',
       title: 'Train Tours'},
    {id: 4, image: 'assets/images/hotel-gala.jpg', logo: '',
      special: 'Save up to 70% on your next holiday accommodation', title: 'Accommodation'},
      {id: 5, image: 'assets/images/hotel-gala.jpg', logo: '',
      special: 'Save up to 70% on your next holiday accommodation', title: 'Sea Cruises'}];

  selectedTravel: any;
    constructor(private http: ApiService) {}

  getTravels(/*payload*/) {
    /*let url = '';

    this.http.post(url, payload).subscribe(res=> {
      this.Travels = res;
    });*/

    return this.travels;
  }

  setSelectedTravel(selected) {
    this.selectedTravel = selected - 1;
    console.log(this.travels[selected]);
  }

  getSelectedTravel() {
    return this.travels[this.selectedTravel];
  }
}
