import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-greydot-rewards',
  templateUrl: './greydot-rewards.page.html',
  styleUrls: ['./greydot-rewards.page.scss'],
})
export class GreydotRewardsPage implements OnInit {

  currentUser;
  items;
  itemNumbers;
  itemNumShow = false;

  constructor(
    private userService: UserService,
    private http: ApiService) { }

  ngOnInit() {
    this.userService.getUser().subscribe(res => {
      console.log(res);
      this.currentUser = res;
    });

    const payload = {
     id: this.currentUser.id
    };

    this.http.post('cart/items', payload).subscribe(res => {
      this.items = res;
      console.log(this.items.length);
      if (this.items.length !== 0) {
          this.itemNumShow = true;
        }
      this.itemNumbers = this.items.length;
    });
  }

}
