import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { IUser } from 'src/app/models/IUser';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-ecommerce-store',
  templateUrl: './ecommerce-store.page.html',
  styleUrls: ['./ecommerce-store.page.scss'],
})
export class EcommerceStorePage implements OnInit {

  productId;
  productName;
  iframeurl;
  currentUser;
  //user;
  isLoading = true;

  constructor(
    private userService: UserService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    // get the current user
    // this.userOldService.getUser().subscribe(res => {
    //   this.currentUser = res;

      this.route.params.subscribe(params => {
        this.productId = params.productId;
        this.productName = params.productName;

        //get the netready user unique code
        this.userService.getUser().subscribe((user: IUser) => {
          //this.user = response;
          const baseUrl = 'https://livelife.group/api/user_LoginAndRedirect?username=';
          const url = `${baseUrl}${user.userName}&url=//products//${this.productId}&apiKey=1cApxPbmuPdTSdIaNobasjlardynzSsK`;
          console.log('Url=', url);

          this.iframeurl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        });
      });
    //});
  }
}
