import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICreateBasketRequest } from 'src/app/models/livelifecoupons/ICreateBasketRequest';
import { ICreateBasketResponse } from 'src/app/models/livelifecoupons/ICreateBasketResponse';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { IAddToBasketRequest } from 'src/app/models/livelifecoupons/IAddToBasketRequest';

@Injectable({
  providedIn: 'root'
})
export class LiveLifeCouponsService {

  baseUrl = 'https://livelife-coupons-api.azurewebsites.net/api/';
  selectedCoupons: string[];

  httpOptions: {
    observe: 'response';
    responseType: 'json';
  };

  constructor(
    private http: HttpClient) { }

    // gets the coupon categories
    getCategories() {
      return this.http.get(`${this.baseUrl}/coupons/categories`);
    };

    // gets the coupon list by category
    getCoupons(categoryId) {
      return this.http.get(`${this.baseUrl}/coupons/${categoryId}`);
    };

    // creates a new basket
    createBasket(createBasketRequest: ICreateBasketRequest): Observable<HttpResponse<ICreateBasketResponse>> {
      return this.http.post<ICreateBasketResponse>(`${this.baseUrl}baskets`, createBasketRequest, this.httpOptions)
        .pipe(catchError(this.handleError));
    };

    addToBasket(addToBasketRequest: IAddToBasketRequest): Observable<HttpResponse<ICreateBasketResponse>> {
      return this.http.post<ICreateBasketResponse>(`${this.baseUrl}baskets/addItem`, addToBasketRequest, this.httpOptions)
        .pipe(catchError(this.handleError));
    };

    // gets the coupon list by category
    removeItem(userId, itemId) {
      return this.http.delete(`${this.baseUrl}/baskets/removeItem/${userId}/${itemId}`);
    };

    // gets the coupon list by category
    getOpenBasket(userId) {
      return this.http.get(`${this.baseUrl}/baskets/getOpenBasket/${userId}`);
    };

    // gets the coupon list by category
    checkOutOpenBasket(userId) {
      return this.http.get(`${this.baseUrl}/baskets/checkOut/${userId}`);
    };

    // collects the coupon for the user
    collectCoupon(userId, couponId) {
      return this.http.get(this.baseUrl + `collectcoupon/${userId}/${couponId}`);
    };

    // collects the coupon for the user
    getUserCoupons(userId) {
      return this.http.get(this.baseUrl + `getusercoupons/${userId}`);
    };

    // collects the coupon for the user
    userRedeemCoupons(redeemRequest) {
      return this.http.post(this.baseUrl + 'userredeemcoupons', redeemRequest);
    };

    private handleError(error: HttpErrorResponse) {
      if (error.status === 0) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('An error occurred:', error.error);
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong.
        console.error(
          `Backend returned code ${error.status}, body was: `, error.error);
      }
      // Return an observable with a user-facing error message.
      return throwError(
        'Something bad happened; please try again later.');
    }
}
