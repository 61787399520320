import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CelbuxService {
  baseUrl = 'https://livelife.azure-api.net/celbux/api/';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  authHeader: HttpHeaders = new HttpHeaders({'Ocp-Apim-Subscription-Key': '7a8f6fda81e548248162dc7904056f09'});

  constructor(
    private http: HttpClient
  ) { }

  // gets the coupon list from the middleware api
  registerUser(appId) {
    return this.http.get(this.baseUrl + `register/createcustomer/${appId}`, {headers: this.authHeader});
  };

  // gets the coupon list from the middleware api
  getUserByAppId(appId) {
    return this.http.get(this.baseUrl + `register/getuserbyappid/${appId}`, {headers: this.authHeader});
  };
}
