import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TabsPage } from './tabs.page';

const routes: Routes = [
  {
    path: 'tabs',
    component: TabsPage,
    children: [
      {
        path: 'home',
        loadChildren: () => import('../home/home.module').then( m => m.HomePageModule)
      },
      {
        path: 'coupons-basket-retailers',
        loadChildren: () => import('../coupons/coupons-basket/coupons-basket-retailers/coupons-basket-retailers.module')
          .then( m => m.CouponsBasketRetailersPageModule)
      },
      {
        path: 'contact',
        loadChildren: () => import('../contact/contact.module').then( m => m.ContactPageModule)
      },
      // {
      //   path: 'coupons-retailers',
      //   loadChildren: () => import('../coupons/coupons-retailers/coupons-retailers.module').then( m => m.CouponsRetailersPageModule)
      // },
      // {
      //   path: 'wallet-consumer',
      //   loadChildren: () => import('../wallet/consumer/consumer.module').then( m => m.ConsumerPageModule)
      // },
      {
        path: 'share',
        loadChildren: () => import('../share/share.module').then( m => m.SharePageModule)
      },
      {
        path: 'userprofile',
        loadChildren: () => import('../userprofile/userprofile.module').then( m => m.UserprofilePageModule)
      },
      {
        path: 'messages',
        loadChildren: () => import('../messages/messages.module').then( m => m.MessagesPageModule)
      },
    ]
  },
  {
    path: '',
    redirectTo: 'tabs/home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TabsPageRoutingModule {}
