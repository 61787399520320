import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from 'src/app/services/user.service';
import { UserOldService } from 'src/app/services/user_old.service';

@Component({
  selector: 'app-payroll',
  templateUrl: './payroll.page.html',
  styleUrls: ['./payroll.page.scss'],
})
export class PayrollPage implements OnInit {

  iframeurl;
  currentUser;
  user;

  constructor(
    private userOldService: UserOldService,
    private userService: UserService,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
    // get the current user
    this.userOldService.getUser().subscribe(res => {
      this.currentUser = res;
    });

    //get the netready user unique code
    this.userService.getUser().subscribe(response => {
      this.user = response;
      const baseUrl = 'https://api-v1-dot-celbuxproducts.appspot.com/login/oauth/authorize';
      const redirectUrl = 'https://livelife-celbux-client.azurewebsites.net/user/index/?t=1&scope=test&state=test';
      const url = 'https://celbuxwallet.co.za/register/wage-payer';
      //`${baseUrl}?ns=EVMProd&client_id=LIVELIFE&response_type=authorization_code&redirect_uri=${redirectUrl}`;

      this.iframeurl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    });
  }

}
