import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { IUser } from 'src/app/models/IUser';
import { CelbuxService } from 'src/app/services/celbux.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-consumer',
  templateUrl: './consumer.page.html',
  styleUrls: ['./consumer.page.scss'],
})
export class ConsumerPage implements OnInit {

  iframeurl;
  currentUser;
  user: IUser;
  isRegistered = true;
  messageText: string;
  showWarning = false;
  isRegistering = false;
  buttonText = 'Register';

  constructor(
    private userService: UserService,
    private sanitizer: DomSanitizer,
    private celbuxService: CelbuxService
  ) { }

  ngOnInit() {

    const baseUrl = 'https://api-v1-dot-celbuxproducts.appspot.com/login/oauth/authorize';
    const redirectUrl = 'https://livelife-celbux-client.azurewebsites.net/user/index/?t=1&scope=test&state=test';
    const url = `${baseUrl}?ns=EVMProd&client_id=LIVELIFE&response_type=authorization_code&redirect_uri=${redirectUrl}`;
    //console.log('Url=', url);

    this.iframeurl = this.sanitizer.bypassSecurityTrustResourceUrl(url);

    // //get the netready user unique code
    // this.userService.getUser().subscribe((response: IUser) => {
    //   this.user = response;

    //     // check if registered - redirect to info page if not
    //     this.celbuxService.getUserByAppId(this.user.appId).subscribe(user => {
    //       console.log('User', user);
    //       if (user == null)
    //       {
    //         this.isRegistered = false;
    //           //this.router.navigate(["/celbuxRegister"]);
    //       }
    //       else
    //       {
    //         this.isRegistered = true;
    //       }
    //     }, error => {
    //       // http call error
    //       this.isRegistered = false;
    //       this.isRegistering = true;
    //       this.buttonText = 'Register';
    //       this.showWarning = true;
    //       this.messageText = 'Server error, please contact support';
    //     });
    // });
  }
}
