import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { IUser } from 'src/app/models/IUser';
import { SacouponsSubscriptionService } from 'src/app/services/coupons/sacoupons-subscription.service';
import { SACouponsService } from 'src/app/services/coupons/sacoupons.service';
import { UserService } from 'src/app/services/user.service';
import { UserOldService } from 'src/app/services/user_old.service';

@Component({
  selector: 'app-coupons-subscribe',
  templateUrl: './coupons-subscribe.page.html',
  styleUrls: ['./coupons-subscribe.page.scss'],
})
export class CouponsSubscribePage implements OnInit {

  iframeurl;
  showBankDetails: boolean;
  user: IUser;

  constructor(
    public router: Router,
    private userService: UserService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private saCouponsSubscriptionService: SacouponsSubscriptionService
  ) { }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      const paymentMethod: string = params.paymentMethod;

      //console.log('PaymentMethod', paymentMethod);
      // get current user
      this.userService.getUser().subscribe ((user: IUser) => {

        this.user = user;
        //console.log('User', user);
        // this.saCouponsSubscriptionService.createSubscription(user.id).subscribe(res => {
        //   console.log('Subscribe Response', res);
        // });
        const PAYFAST_SANDBOX_URL = 'https://sandbox.payfast.co.za/eng/process?merchant_id=10004002&merchant_key=q1cd2rdny4a53';
        const PAYFAST_LIVE_URL = 'https://payfast.co.za/eng/process?merchant_id=18072236&merchant_key=k3rctwp0cgs4d';

        const PAYFAST_URL = {
          baseUrl: PAYFAST_LIVE_URL,
          userId: `custom_int1=${user.id}`,
          userName: `custom_str1=${user.userName}`,
          amount: 'amount=30.00',
          itemName: 'item_name=LiveLife+Coupon+Subscription',
          itemDescription: 'item_name=LiveLife+Coupon+Subscription',
          notifyUrl: 'notify_url=https://livelife-sacoupons-api.azurewebsites.net/api/subscriptions/processpayment',
          returnUrl: 'return_url=https://livelife-sacoupons-api.azurewebsites.net/api/subscriptions/confirmpayment',
          paymentMethod: `payment_method=${paymentMethod}`,
          //passPhrase: 'payfast'
        };

        const celBuxBaseUrl = 'https://api-v1-dot-celbuxproducts.appspot.com/login/oauth/authorize';
        const redirectUrl = 'https://livelife-celbux-client.azurewebsites.net/user/index/?t=1&scope=test&state=test';
        const celBuxUrl = `${celBuxBaseUrl}?ns=EVMProd&client_id=LIVELIFE&response_type=authorization_code&redirect_uri=${celBuxBaseUrl}`;

        const payFastUrl = `${PAYFAST_URL.baseUrl}&${PAYFAST_URL.amount}&${PAYFAST_URL.itemName}&${PAYFAST_URL.notifyUrl}&
                                  ${PAYFAST_URL.returnUrl}&${PAYFAST_URL.userId}&${PAYFAST_URL.userName}&${PAYFAST_URL.paymentMethod}`;

        const payFastBaseUrl = 'https://payfast.co.za/eng/process?merchant_id=18072236&merchant_key=k3rctwp0cgs4d';
        const details = '&m_payment_id=1&amount=30.00&item_name=Coupons&item_description=Coupon+Subscription';
        const subscription = '&subscription_type=1&billing_date=2021-07-20&recurring_amount=30.00&frequency=3&cycles=3';


        const url = payFastUrl;// `${payFastBaseUrl}${details}`;

        // if (subscriptionType === 'recurring') {
        //   url = url;// `${url}${subscription}`;
        // } else if (subscriptionType === 'single') {
        //   url = url;
        // } else if (subscriptionType === 'wallet') {
        //   url = celBuxUrl;
        // } else if (subscriptionType === 'eft') {
        //   this.showEft = true;
        // } else {
        //   url = '';
        // }

        // check payment method
        if (paymentMethod === 'directDeposit')
        {
          this.showBankDetails = true;
        } else {
          this.iframeurl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        }
      });
    });
  }
}
