import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { GreydotRewardsPage } from './greydot-rewards.page';

const routes: Routes = [
  {
    path: '',
    component: GreydotRewardsPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GreydotRewardsPageRoutingModule {}
