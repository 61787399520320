import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { AdvertCouponComponent } from '../components/adverts/advert-coupon/advert-coupon.component';
import { IAdvert } from '../models/IAdvert';
import { AdvertService } from '../services/advert.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.page.html',
  styleUrls: ['./tabs.page.scss'],
})
export class TabsPage implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private advertService: AdvertService,
    public modalController: ModalController,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      // check if there are any adverts
      this.advertService.getAdvert().subscribe((advert: IAdvert) => {
        if (advert != null)
        {
          console.log(advert);
          if (advert.type === 'SACoupon')
          {
            this.showCoupon();
          }
        }
      });
    });
  }

  async showCoupon() {
    //console.log(coupon);
    const modal = await this.modalController.create({
      component: AdvertCouponComponent,
      cssClass: 'my-custom-class'
    });
    return await modal.present();
  }
}
