import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAdvert } from '../models/IAdvert';

@Injectable({
  providedIn: 'root'
})
export class AdvertService {

  advert: IAdvert;

  // using the coupons api for now - dedicated advert api needs to be created
  baseUrl = 'https://livelife-sacoupons-api-staging.azurewebsites.net/api/';

  constructor(
    private http: HttpClient
  ) { }

    // gets the advert from the api
    getAdvertById(id) {
      return this.http.get(`${this.baseUrl}adverts/${id}`);
    };

    // returns user from memory
    getAdvert() {
      // return this.user;
      return new Observable(o => {
        o.next(this.advert);
      });
    }

    // sets user in memory so it can be retrieved from anyway
    setAdvert(advert: IAdvert)
    {
      this.advert = advert;
    }

    // clears advert so it does not pop up again
    clearAdvert()
    {
      this.advert = null;
    }
}
