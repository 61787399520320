import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CouponService } from 'src/app/services/coupon.service';
import { UserService } from 'src/app/services/user.service';
import { UserOldService } from 'src/app/services/user_old.service';
import { PhotoService } from 'src/app/services/photo.service';

@Component({
  selector: 'app-proof-of-purchase',
  templateUrl: './proof-of-purchase.page.html',
  styleUrls: ['./proof-of-purchase.page.scss'],
})
export class ProofOfPurchasePage implements OnInit {

  buttonText = 'Submit';
  submitting = false;
  proofOfPurchaseImage: any;
  currentUser;
  netReadyUser;

  constructor(
    private userOldService: UserOldService,
    private userService: UserService,
    private couponService: CouponService,
    private router: Router,
    // private location: Location,
    public photoService: PhotoService
  ) { }

  ngOnInit() {
    // get the current user's info
    this.userOldService.getUser().subscribe(res => {
      this.currentUser = res;

      // get the user's profile data
      this.userService.getUserProfile(this.currentUser.app_id).subscribe(response => {
        this.netReadyUser = response;
      });
    });
  }

  onSubmit() {
    // this.submitting = true;
    // this.buttonText = 'Submitting...';
    const redeenCouponRequest = {
      userId: this.netReadyUser.id,
      couponCodes: this.couponService.selectedCoupons,// ['312323', '3453453'],
      proofOfPurchaseImage: this.proofOfPurchaseImage
    };

    this.couponService.userRedeemCoupons(redeenCouponRequest).subscribe(response =>{
      console.log(response);
      //return home
      this.router.navigateByUrl('rewards/' + this.currentUser.api_token);
    });
  }

  addPhotoToGallery() {
    this.photoService.addNewToGallery();
  }

  // onFileSelected(event)
  // {
  //   let reader = new FileReader();
  //   let selectedFile = event.target.files[0];

  //   reader.readAsDataURL(selectedFile);
  //   // Setup onload event for reader
  //   reader.onload = () => {
  //     // Store base64 encoded representation of file
  //     //this.selectedFile.fileAsBase64 = reader.result.toString();
  //         // resize image
  //     const img = new Image();
  //     let base64Image = reader.result.toString();

  //     img.src = base64Image;
  //     img.onload = () => {
  //       const elem = document.createElement('canvas');
  //       elem.width = 300;
  //       elem.height = 500;
  //       const ctx = elem.getContext('2d');
  //       ctx.drawImage(img, 0, 0, 300, 500);
  //       const data = ctx.canvas.toDataURL();
  //       this.proofOfPurchaseImage = data;
  //     }
  //   }
  // }

}
